<template>
  <div class="ele-body">
    <el-card shadow="never">
      <el-tabs v-model="active" class="user-info-tabs">
        <!--课程列表-->
        <el-tab-pane label="课程列表" name="course_data">
          <div class="ele-table-tool ele-table-tool-default">
            <el-button @click="showEdit=true" type="primary" icon="el-icon-plus" class="ele-btn-icon" size="small" v-if="permission.includes('sys:course:add')">添加
            </el-button>
          </div>
          <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row>
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" fixed="left"/>
              <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>
              <el-form-item label="封面图：" >
                <uploadImage :limit="1" v-model="editForm.image"></uploadImage>
              </el-form-item>
              <el-table-column prop="type_name" label="显示端"  show-overflow-tooltip min-width="100"/>
              <el-table-column prop="name" label="标题"  show-overflow-tooltip min-width="300"/>
              <el-table-column label="更新时间"  show-overflow-tooltip min-width="160">
                <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
              </el-table-column>
              <el-table-column prop="num" label="浏览次数(次)"  show-overflow-tooltip min-width="250"/>
              <el-table-column label="操作" width="140px" align="center" :resizable="false"  fixed="right">
                <template slot-scope="{row}">
                  <el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('sys:course:edit')">修改</el-link>
                  <el-popconfirm title="确定要删除此课程吗？" @confirm="remove(row)" class="ele-action">
                    <el-link slot="reference" icon="el-icon-delete" type="danger" :underline="false" v-if="permission.includes('sys:course:delete')">删除</el-link>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </template>
          </ele-data-table>
        </el-tab-pane>
        <!--学习情况-->
        <el-tab-pane label="学习情况" name="course_study">
          <ele-data-table ref="table1" :config="table1" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row>
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" fixed="left"/>
              <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>
       
              <el-table-column prop="type_name" label="类型"  show-overflow-tooltip min-width="60px"/>
              <el-table-column prop="name" label="商家名称"  show-overflow-tooltip /> 
              <el-table-column prop="type_name" label="车牌号"  show-overflow-tooltip />
              <el-table-column prop="phone" label="手机号"  show-overflow-tooltip />
              <el-table-column prop="address" label="地区"  show-overflow-tooltip />
              <el-table-column label="最近学习时间"  show-overflow-tooltip >
                <template slot-scope="{row}">{{ row.active_time*1000 | toDateString }}</template>
              </el-table-column>
              <el-table-column prop="num" label="今日学习数量"  show-overflow-tooltip/>
              <el-table-column prop="num" label="累计学习数量"  show-overflow-tooltip/>
              <el-table-column label="操作" width="140px" align="center" :resizable="false"  fixed="right">
                <template slot-scope="{row}">
                  <el-link @click="handleDriverDetail(row)" icon="el-icon-view" type="primary" :underline="false" v-if="permission.includes('sys:course:edit')">查看</el-link>
                  <el-link @click="statistical(row)" icon="el-icon-finished" type="primary" :underline="false" v-if="permission.includes('sys:course:edit')">统计</el-link>
              
                </template>
              </el-table-column>
            </template>
          </ele-data-table>
        </el-tab-pane>
        <!--轮播图-->
        <el-tab-pane label="轮播图" name="course_banner">
          <div class="ele-table-tool ele-table-tool-default">
            <el-button @click="dialogTableVisiblebannerAdd=true" type="primary" icon="el-icon-plus" class="ele-btn-icon" size="small" v-if="permission.includes('sys:banner:add')">添加
            </el-button>
          </div>
          <el-form :model="table.where" label-width="77px" class="ele-form-search"
                   @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
          </el-form>
          <div class="ele-table-tool ele-table-tool-default">
            <el-button @click="showEdit=true" type="primary" icon="el-icon-plus" class="ele-btn-icon" size="small" v-if="permission.includes('sys:course:bannneradd')">添加
            </el-button>
          </div>
          <ele-data-table ref="table2" :config="table2" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row>
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" fixed="left"/>
              <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>
              
               <el-table-column prop="img" label="轮播图"  show-overflow-tooltip  >
                <template slot-scope="{row}">
                  <el-avatar shape="square" :size="20" :src="row.img" style="width:80px;height:30px"/>
                </template>
              </el-table-column>
               <el-table-column label="操作" width="140px" align="center" :resizable="false"  fixed="right">
                <template slot-scope="{row}">
                  <el-link @click="handleBannerEdit(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('sys:banner:edit')">编辑</el-link>
                  <el-popconfirm title="确定要删除此课程吗？" @confirm=" banner_remove(row)" class="ele-action">
                    <el-link slot="reference" icon="el-icon-delete" type="danger" :underline="false" v-if="permission.includes('sys:banner:delete')">删除</el-link>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </template>
          </ele-data-table>
        </el-tab-pane>  
      </el-tabs>
    </el-card>
     <!-- 添加课程轮播图 -->
    <el-dialog v-dialogDrag title="添加课程轮播图" ref="bannerForm" :rules="bannerRules" :visible.sync="dialogTableVisiblebannerAdd" width="600px"
            :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
      <el-form  label-width="82px">
          <el-form-item label="课程轮播图：" >
            <uploadImage :limit="1" v-model="bannerIMG"></uploadImage>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogTableVisiblebannerAdd=false">取消</el-button>
        <el-button type="primary" @click="bannerSave()">保存</el-button>
      </div>
    </el-dialog>
    <!-- 编辑课程轮播图 -->
    <el-dialog v-dialogDrag title="编辑课程轮播图" :visible.sync="dialogTableVisiblebannerEdit" width="600px"
           :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
      <el-form  label-width="82px">
          <el-form-item label="课程轮播图：" >
            <uploadImage :limit="1" v-model="dialogTableVisiblebannerEdit.img"></uploadImage>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
    <!-- 添加课程学习弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'修改课程详情':'添加课程'" :visible.sync="showEdit" width="600px"
           @closed="editForm={}" :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
      <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="82px">
        <el-row :gutter="15">
          <el-col :sm="12">
              <el-form-item label="显示端:">
                <el-select v-model="editForm.type_name" placeholder="请选择" clearable class="ele-fluid">
                  <el-option  label="司机端" value="1"/>
                  <el-option label="商超" value="2"/>
                  <el-option label="跑腿" value="5"/>
                </el-select>
              </el-form-item>
              <el-form-item label="课程名:" prop="title">
                  <el-input v-model="editForm.name" placeholder="" clearable/>
              </el-form-item>
              <el-form-item label="课程封面图：" >
                <uploadImage :limit="1" v-model="editForm.image"></uploadImage>
              </el-form-item>
          </el-col>
        </el-row>
        <!-- 富文本编辑器 -->
        <tinymce-editor v-model="editForm.content" :init="editContent"/>
      </el-form>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog v-dialogDrag  :visible.sync="showEdit" width="600px"
           @closed="editForm={}" :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
      <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="82px">
        <el-row :gutter="15">
          <el-col :sm="12">
              <el-form-item label="显示端:">
                <el-select v-model="editForm.type_name" placeholder="请选择" clearable class="ele-fluid">
                  <el-option  label="司机端" value="1"/>
                  <el-option label="商超" value="2"/>
                  <el-option label="跑腿" value="5"/>
                </el-select>
              </el-form-item>
              <el-form-item label="课程名:" prop="title">
                  <el-input v-model="editForm.name" placeholder="" clearable/>
              </el-form-item>
              <el-form-item label="课程封面图：" >
                <uploadImage :limit="1" v-model="editForm.image"></uploadImage>
              </el-form-item>
          </el-col>
        </el-row>
        <!-- 富文本编辑器 -->
        <tinymce-editor v-model="editForm.content" :init="editContent"/>
      </el-form>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>


    <!-- 查看商家详情 -->
    <el-dialog v-dialogDrag title="查看课程详情" :visible.sync="dialogTableVisibleDriverDetail" width="850px" :destroy-on-close="true" :lock-scroll="false">
        <div>
         <div><span>商家姓名：</span>{{courseDateil.name}} </div>
          <div><span>类型：</span>{{courseDateil.type}}  </div>
          <div><span>手机号：</span>{{courseDateil.phone}}  </div>
          <div><span>地区：</span>{{courseDateil.area}}  </div>
        </div>
        <div class="greenLine"></div>
        <div class="block">
          <el-timeline :reverse="reverse">
            <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.name">
              {{activity.end_time}}
            </el-timeline-item>
          </el-timeline>
        </div>
    </el-dialog>
    <!-- 查看课程详情 -->
    <el-dialog v-dialogDrag title="查看课程详情" :visible.sync="dialogTableVisibleCourse" width="850px" :destroy-on-close="true" :lock-scroll="false">
        <div>
          <div><span>商家姓名：</span>{{driverData.driver_name}} </div>
          <div><span>类型：</span>{{driverData.type}}  </div>
          <div><span>手机号：</span>{{driverData.phone}}  </div>
          <div><span>地区：</span>{{driverData.area}}  </div>
        </div>
        <div class="greenLine"></div>
        <div>学习记录统计</div>
        <div id="main" style="width:100%;height:300px;">    
        </div>
    </el-dialog>
  </div>

</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import { mapGetters } from "vuex";



import uploadImage from '@/components/uploadImage';
export default {
  name: "SysNotice",
  components: {TinymceEditor,uploadImage},
  data() {
    return {
      reverse: true,
      
      activities:[
       
      ],
       
      active:'course_data',
      table: {url: '/course/index', where: {}},  // 表格配置
      table1: {url: '/course/get_study_list', where: {}},  // 表格配置
      table2: {url: '/course/banner_index', where: {}},  // 表格配置
      driverData:{},
      courseDateil:{},
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      bannerEdit:false,
      dialogTableVisibleCourse:false,
      dialogTableVisibleDriverDetail:false,
      dialogTableVisiblebannerAdd:false,
      dialogTableVisiblebannerEdit:false,
      editForm: {},  // 表单数据
      bannerIMG:[],
      editRules: {  // 表单验证规则
        name: [
          {required: true, message: '请输入课程标题', trigger: 'blur'}
        ],
        image: [
          {required: true, message: '请上传课程封面图', trigger: 'blur'}
        ],
        type_name: [
          {required: true, message: '请选择显示端', trigger: 'blur'}
        ],
      },
      bannerRules:{
        image: [
          {required: true, message: '请上传课程封面图', trigger: 'blur'}
        ],
        bannerForm:[],

      },
      // 自定义文件上传(这里使用把选择的文件转成blob演示)
      file_picker_callback: (callback, value, meta) => {
        let input = document.createElement('input');
        input.setAttribute('type', 'file');
        // 设定文件可选类型
        if (meta.filetype === 'image') {
          input.setAttribute('accept', 'image/*');
        } else if (meta.filetype === 'media') {
          input.setAttribute('accept', 'video/*');
        }
        input.onchange = () => {
          let file = input.files[0];
          let reader = new FileReader();
          reader.onload = (e) => {
            let blob = new Blob([e.target.result], {type: file.type});
            callback(URL.createObjectURL(blob));
          };
          reader.readAsArrayBuffer(file);
        }
        input.click();
      }
    }
  },
  computed: {
    ...mapGetters(["permission"]),

    editContent() {
      return {
        menubar: false,
        // file_picker_callback: this.file_picker_callback,
        skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' : '/tinymce/skins/ui/oxide',
        content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' : '/tinymce/skins/content/default/content.css'
      };
    }
  },



  mounted() {
  },
  methods: {
    handleBannerEdit(row){
      this.dialogTableVisiblebannerEdit=true
      this.bannerIMG=row.img
    },
    handleDriverDetail(row){
      this.dialogTableVisibleDriverDetail=true
      this.$http.post('/course/get_show_study',{id:row.id}).then(res=>{
        this.courseDateil=res.data.driver
        this.activities=res.data.coursestudy
        //console.log(res)
        
      })
    },
    statistical(row){
      this.dialogTableVisibleCourse=true
      this.$http.post('/course/get_study_log',{id:row.id}).then(res=>{
        var data=res.data
        //console.log(data)
          this.driverData=data.driver_data
         var stringXResult=data.x_datas
         var  stringYResult=data.y_datas
         var xData = stringXResult.split(',')
         var yData= stringYResult.split(',')
          //console.log(xData)
           //console.log(yData)
           this.$nextTick(()=>{
            let dom = document.getElementById('main')
            var myChart = this.$echarts.init(dom);
            var option;
            option = {
              
              toolbox: { 
                  show: true,
                  feature: {
                      saveAsImage: {//保存图片
                          show: true
                      },
                  }
              },
          
                xAxis: {
                    type: 'category',
                    data: xData
                },
                yAxis: {
                    type: 'value',
          
                },
                series: [{
                    data:yData,
                    type: 'line'
                }],
              
            };
            option && myChart.setOption(option);
          })
      
      })
   

    },
    /* 显示编辑 */
    edit(row) {
      this.editForm = Object.assign({}, row);
      this.showEdit = true;
    },
    /* 保存编辑 */
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});
          this.$http.post('/course/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /* 删除 */
    remove(row) {
      if (!row) {  // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除选中的课程吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/course/delete', {id: ids}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else {  // 单个删除
        const loading = this.$loading({lock: true});
        this.$http.post('/course/delete', {id:row.id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      }
    },
    /* 添加轮播图 */
    bannerSave(){

          const loading = this.$loading({lock: true});
          this.$http.post('/course/banner_add', this.bannerIMG).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
      

    },

  }
}
</script>

<style scoped>
.ele-block  .el-upload .el-upload-dragger {
  width: 100%;
}
</style>